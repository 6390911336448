@import "../../../Variables.scss";

.HeaderContainer {
  background-color: $SlateBlue;

  .HeaderInner {
    max-width: $BreakL;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    text-decoration: none;

    .HeaderLogoContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      transition: text-shadow 0.5s ease;
      text-shadow: none;
    }

    &:hover,
    &:focus {
      .HeaderLogoContainer {
        text-shadow: 0 0 2px $DarkSalmon;
      }
    }
  }
}

.HeaderNavigationList {
  list-style: none;
  display: flex;
  margin: 0 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
}

.HeaderTitle {
  font-size: x-large;
  margin: 10px;
  color: $White;
}

@media screen and (max-width: $BreakM) {
  .HeaderContainer {
    .HeaderInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
  }

  .HeaderLogoContainer {
    padding-top: 0;
  }

  .HeaderNavigationList li {
    padding-bottom: 5px;
  }
}
