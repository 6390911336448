@import "../../../Variables.scss";
.Door-Button {
  &-Container {
    width: 300px;
    padding: 10px;

    a {
      text-decoration: none;
      .Door-Button-Door {
        transition-duration: 0.5s;
      }
      &:hover,
      &:focus {
        .Door-Button-Door {
          -webkit-filter: drop-shadow(0 0 5px $DarkSalmon)
            drop-shadow(0 0 5px $DarkSalmon);
          filter: drop-shadow(0 0 5px $DarkSalmon)
            drop-shadow(0 0 5px $DarkSalmon);

          transform: scale(0.98);
        }
      }
    }
  }

  &-Label {
    background-color: $Tequila;
    text-align: center;
    margin-top: -6px;
    padding: 8px;
    font-weight: bold;
    font-size: larger;
    border-radius: 8px;
  }
}
