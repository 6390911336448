@use "sass:math";

.Home {
  &-Container {
    background-image: url("../../assets/images/sunset-beach.jpg");
    background-size: cover;
    background-position: center center;
    height: 100%;
    color: white;

    .Snow-Home-Container {
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .Fireworks-Home-Container {
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .Rain-Container {
      overflow: hidden;
      position: absolute;
      height: 100%;
      width: 100%;

      .drop {
        position: absolute;
        background-color: rgba(white, 0.7);
        width: 1px;
        height: 25px;
        animation: homefall 600ms linear infinite;

        @for $i from 1 through 30 {
          &:nth-child(#{$i}) {
            left: random(50) * 2%;
            animation-delay: $i * -0.03s;
            animation-duration: random(100) + 600ms;
          }
        }
      }
    }
  }

  &-Door-Container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
  }

  @media (min-width: 800px) {
    &-Door-Container {
      min-height: calc(100% - 80px);
      width: 100%;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-end;
    }
  }
}

@keyframes homefall {
  0% {
    transform: rotate(25deg) translateX(0) translateY(0);
  }
  100% {
    transform: rotate(25deg) translateX(1% * math.tan(25deg)) translateY(100vh);
  }
}
