@import "../../../Variables.scss";

.HeaderButtonContainer {
  text-decoration: none;
  color: white;

  .HeaderButton {
    border: $TopOutline 1px solid;
    border-radius: 6px;
    padding: 6px 12px;
    margin: 0 4px;
    transition: 0.3s;
  }

  &:hover,
  &:focus {
    .HeaderButton {
      background-color: $TopHover;
      cursor: pointer;
    }
  }
}
