@import "../../../Variables.scss";

.Breadcrumb {
  &-Outer {
    margin: 0 auto;
    max-width: $BreakL;
    position: relative;
  }
  &-Inner {
    position: absolute;
    top: 5px;
    left: 10px;
    background-color: $White;
    border-radius: 20px;
    z-index: 100;
  }
  &-Text {
    padding-right: 5px;

    &:not(:last-of-type):after {
      content: ">";
      margin-left: 5px;
    }
  }
  &-Link {
    display: inline-block;
    height: 100%;
    padding: 10px;
    text-decoration-color: $Blackberry;
    span {
      color: $Blackberry;
    }
    &:focus,
    &:hover {
      text-decoration-color: $SlateBlue;
      span {
        color: $SlateBlue;
      }
    }
  }
}
