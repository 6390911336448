.FourOhFour {
  &-Container {
    position: relative;
    font-family: "Indie Flower", cursive;
  }
  &-Scroll {
    display: none;
    width: 622px;
    margin: 40px auto;
    @media (min-width: 750px) {
      display: block;
    }
    &-Text-Container {
      font-size: larger;
      margin: 40px;
      background-color: #e8e4d4;
      padding: 20px;
      border-radius: 8px;
      opacity: 0.85;
      @media (min-width: 750px) {
        background-color: #ffffff00;
        position: absolute;
        top: 0;
        padding: 0;
        width: 400px;
        margin: 120px 20px 100px 40px;
        opacity: 1;
        left: 50%;
        transform: translateX(-50%);
      }

      & h1 {
        margin-top: 0;
      }
    }
  }

  &-One-Liner-Container {
    white-space: pre-wrap;
  }
}
