//=================================================================
//  Theme Colors
//=================================================================
$Black: #000000;
$Blackberry: #381223;
$Cadillac: #a24c65;
$MiddleBlueGreen: #88d9d7;
$SlateBlue: #625ace;
$Tamarillo: #782a30;
$Tequila: #f4d5a1;
$TrueV: #9679cd;
$White: #ffffff;

//=================================================================
//  Other Colors
// TODO: Remove colors that are only used in one place or so.
//=================================================================
$Apple: #3ea42f;
$Blonde: #f9ebb7;
$BlueDiamond: #542860;
$BlueRibbon: #7e98f9;
$Bone: #e8e4d4;
$BrightGray: #efefef;
$CharlestonGreen: #2c2c2c;
$Cloud-2-Color: #d3d3d3;
$Cranberry: #e96a49;
$CyberGrape: #3d3968;
$DarkGold: #a3682e;
$DarkSalmon: #e98a81;
$EarthYellow: #d6a867;
$GoldCrayola: #e4c695;
$Granite: #686868;
$JetStream: #bee3c0;
$LavenderPurple: #938ec1;
$LightBeige: #d1a776;
$Oasis: #fff1c5;
$OuterSpace: #454545;
$RaisinBlack: #222222;
$RossoCorsa: #d20404;
$Sepia: #6c4116;
$Sky-Blue: #8bc9e4;
$Sky-Dark: #133061;
$Sunset-Color-2: #fb8caa;
$Sunset-Color: #9795ef;
$TurquoiseGreen: #a7d7af;

// TODO: get rid of these.
$TopBackground: $SlateBlue;
$TopHover: $BlueDiamond;
$TopOutline: $Tequila;

//=================================================================
//  Font Sizes
// TODO: Standardize these
//=================================================================
$fixedSize0: 10px;
$fixedSize1: 11px;
$fixedSize2: 13px;
$fixedSize3: 18px;
$fixedSize4: 20px;
$fixedSize5: 22px;
$fixedSize6: 24px;
$fixedSize7: 20px;
$sizeL: larger;
$sizeM: medium;
$sizeXL: x-large;
$size0: 0.85em;
$size1: 0.9em;
$size2: 1em;
$size3: 1.2em;
$size4: 1.5em;
$size5: 1.6em;
$size6: 2em;
$size7: 2.5em;
$size8: 3.5em;

//=================================================================
//  Scale for Padding and Margin
//=================================================================
$scale0: 5px;
$scale1: 10px;
$scale2: 20px;
$scale3: 25px;
$scale4: 40px;

//=================================================================
//  Breakpoints
//=================================================================
$BreakXS: 420px;
$BreakS: 550px;
$BreakMS: 700px;
$BreakM: 800px;
$BreakL: 1200px;
