@import "../../../Variables.scss";

.FooterContainer {
  background-color: $Blackberry;
  overflow: hidden;
  color: $White;
  position: relative;
  min-height: 100px;
  @media screen and (min-width: $BreakS) {
    min-height: 68px;
  }
  @media screen and (min-width: $BreakMS) {
    min-height: 58px;
  }

  .CopyrightContainer {
    text-align: center;
    transform: rotate(-4deg);
    margin: 20px;
    @media screen and (max-width: $BreakMS) {
      padding-bottom: 25px;
    }
  }

  .PrivacyPolicyContainer {
    position: absolute;
    right: 20px;
    bottom: 20px;
    a {
      color: $White;
    }
  }
}
